import Vue from 'vue';
import VueRouter from 'vue-router';
import md5 from 'md5';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: () => import('../views/DashboardPage.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginPage.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('SpqToken');
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const hashedToken = {
      spq: md5(`${process.env.VUE_APP_SALT}e2123c2743d7a64f3203e4dae224dedc`),
      cz: md5(`${process.env.VUE_APP_SALT}4ee9d19fa2c0c6dbd427d652002b6a02`),
    };
    if (token && (token === hashedToken.spq || token === hashedToken.cz)) {
      next();
    } else {
      next({ name: 'login' });
    }
  } else {
    next();
  }
});

export default router;
