import Vue from 'vue';
import VueApexCharts from 'vue-apexcharts';
import CountryFlag from 'vue-country-flag';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import i18n from './i18n';

Vue.config.productionTip = false;
Vue.component('ApexChart', VueApexCharts);
Vue.component('country-flag', CountryFlag);

new Vue({
  router,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
